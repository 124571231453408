<template>
  <label class="toggler" :class="{ checked }" :for="id">
    <input type="checkbox" v-model="checked" hidden :id="id" />
    <div class="toggler__circle" />
  </label>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    id: {
      type: String,
      default: "toggler"
    },
    defaultValue: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      checked: false
    };
  },
  watch: {
    checked(value) {
      this.$emit("input", value);
    },
    defaultValue: {
      handler(val) {
        if (val) this.$nextTick(() => (this.checked = val));
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./toggle.scss";
</style>
