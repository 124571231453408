<template>
  <div>
    <div
      @click="show = !show"
      :class="`flex flex__spaced flex__item-center cursor-pointer accordion__toggle ${type}`"
    >
      <span class="accordion__toggle-title fw-600 text-capitalize">
        {{ title }}
      </span>
      <div class="accordion__icon" :class="{ active: show }">
        <img src="../../assets/icons/down-arrow.svg" alt="down-arrow" />
      </div>
    </div>
    <div class="accordion" :class="[show ? 'show' : '', type]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "Accordion",
  props: {
    title: {
      type: String,
      default: "Title"
    },
    wasOpen: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "primary"
    }
  },
  data() {
    return {
      show: false
    };
  },
  watch: {
    wasOpen: {
      handler() {
        if (this.wasOpen) {
          this.show = !this.show;
        }
      },
      immediate: true
    }
  }
};
</script>

<style scoped lang="scss">
.accordion {
  max-height: 0px;
  overflow: hidden;
  transition: 0.2s all ease-out;
  border-bottom: 1px solid transparent;

  &__toggle {
    &-title {
      color: #4e4e5a;
      font-size: 24px;
    }

    &.secondary {
      background: #fafafa;
      padding: 20px 22px;
      border-radius: 4px;

      & .accordion__toggle-title {
        font-size: 18px;
      }
    }
  }

  &.show {
    max-height: 1200px;
    padding: 3rem 0;
    overflow: unset;
    border-bottom: 1px solid #a1a4b1;

    &.secondary {
      border-bottom: 1px solid #f0f1f3;
    }
  }

  &__icon {
    transition: 0.2s all ease-out;

    &.active {
      transform: rotateZ(180deg);
    }
  }
}
</style>
