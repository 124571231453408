<template>
  <div class="sub-page">
    <h2>Educations</h2>
    <hr class="mt-2" />
    <Accordion
      v-for="(education, index) in formData"
      :key="index"
      type="secondary"
      :title="education.degree.title + ' - ' + education.institute"
      class="mb-3 mt-3"
    >
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="">
          <div class="grid grid__layout gap-2 mb-8 grid__mobile">
            <div class="col-6 m-col-12 u-above">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Degree Name
              </label>
              <Dropdown
                :options="degrees"
                @selected="updateSelected($event, 'degreeId', index)"
                :placeholder="education.degree.title"
                :border="true"
              />
            </div>

            <Input
              class="col-6 m-col-12"
              type="text"
              placeholder="University"
              label="University / Academy"
              v-model="education.institute"
              name="University"
              rules="required"
            />
            <div class="col-6 m-col-12">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Start Date
              </label>
              <Datepicker
                v-model="education.startDate"
                name="Start Date"
                placeholder="Start Date"
                :disabled-dates="disableFuture"
              />
            </div>
            <div class="col-6 m-col-12">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                End Date
              </label>
              <Datepicker
                v-model="education.endDate"
                name="End Date"
                placeholder="End Date"
                :disabled="!education.startDate"
                :disabled-dates="disabled_dates(index)"
              />
            </div>
            <!-- <div class="col-6 m-col-12 flex flex-end">
              <div class="flex flex__item-center">
                <span class="fs-14">Still going on</span>&nbsp;
                <toggle
                  id="still-working"
                  v-model="education.stillGoing"
                  :defaultValue="education.stillGoing"
                />
              </div>
            </div> -->
            <div class="col-6" />
            <div class="col-12">
              <label class="fs-14 fw-600 text__dark-grey mb-1x">
                Course of Study
              </label>
              <textarea
                class="h-16 p-2"
                placeholder="Course of Study"
                v-model="education.details"
                rules="required"
              ></textarea>
            </div>
          </div>

          <div class="flex flex__spaced">
            <Button
              :disabled="deleting"
              class="w-20"
              :loading="deleting"
              theme="red"
              @click="confirmDelete(education)"
              btnType="button"
            >
              Delete
            </Button>
            <Button
              :disabled="invalid || loading || !education.details"
              class="w-20"
              :loading="loading"
              theme="blue"
              @click="updateEducation(education.id)"
            >
              Save Changes
            </Button>
          </div>
        </form>
      </ValidationObserver>
    </Accordion>

    <div class="flex flex-end">
      <Button class="w-20" theme="blue" @click="addModal = true">
        Add New
      </Button>
    </div>

    <NewModal v-model="addModal" target="modal-portal">
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="">
          <h2 class="text-center">Add new Education</h2>

          <div class="mt-3">
            <Dropdown
              :options="degrees"
              @selected="selectDegree($event)"
              placeholder="Select your Degree/Certification"
              :border="true"
            />
          </div>
          <div class="mt-3">
            <Input
              type="text"
              placeholder="College/University/Academy"
              v-model="newEducation.institute"
              name="College"
              rules="required"
            />
          </div>
          <div class="mt-3">
            <Datepicker
              v-model="newEducation.startDate"
              name="Start Date"
              placeholder="Start Date"
              :disabled-dates="disableFuture"
            />
          </div>

          <!-- <div class="flex flex__item-center">
            <span class="fs-14">Still going on</span>&nbsp;
            <toggle
              id="still-working"
              v-model="newEducation.stillGoing"
              :defaultValue="newEducation.stillGoing"
            />
          </div> -->

          <Datepicker
            class="mt-3"
            v-model="newEducation.endDate"
            name="End Date"
            placeholder="End Date"
            :disabled="!newEducation.startDate"
            :disabled-dates="disabled_dates_for_new_education"
          />

          <textarea
            class="h-16 p-2 mt-4"
            placeholder="Course of Study"
            v-model="newEducation.details"
          ></textarea>

          <div class="flex flex-end mt-4">
            <Button
              :disabled="invalid || addingEducation"
              class="w-20"
              :loading="addingEducation"
              theme="blue"
              @click="addNewEducation"
            >
              Add
            </Button>
            <!-- <Button
          :disabled="false || invalid || loading || !education.details"
          class="w-20"
          :loading="loading"
          theme="blue"
          @click="addNewEducation"
        >
          Add
        </Button> -->
          </div>
        </form>
      </ValidationObserver>
    </NewModal>

    <NewModal v-model="deleteModal" target="modal-portal">
      <h2 class="text-center mt-3" v-if="selectedEducation.degree">
        Are you sure? You are going to delete - <br />
        '{{ selectedEducation.degree.title }} -
        {{ selectedEducation.institute }}'
      </h2>
      <div class="flex flex__spaced mt-5">
        <Button class="w-20" theme="red" @click="deleteNow"> Yes </Button>
        <Button class="w-20" theme="blue" @click="deleteModal = false">
          No
        </Button>
      </div>
    </NewModal>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion';
import Toggle from '@/components/Toggle';
import Datepicker from 'vuejs-datepicker';
import API from '@/const';
import { mapActions } from 'vuex';
import jobseekerService from '../../services/api/jobseekerservice';
import seekerService from '../../services/api/seekerService';
import authService from '../../services/api/authService';

export default {
  name: 'Education',
  props: {
    profileData: {
      type: Object
    }
  },
  components: {
    Accordion,
    Datepicker,
    Toggle
  },

  data() {
    return {
      formData: [...this.profileData.educations],
      degrees: [],
      newEducation: {
        degreeId: '',
        institute: '',
        startDate: '',
        endDate: '',
        details: '',
        stillGoing: false
      },
      loading: false,
      addingEducation: false,
      addModal: false,
      deleteModal: false,
      deleting: false,
      selectedEducation: {}
    };
  },
  computed: {
    disableFuture() {
      return {
        ranges: [
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    },
    disabled_dates_for_new_education() {
      return {
        ranges: [
          {
            from: new Date(0, 0, 0),
            to: this.newEducation.startDate || new Date()
          },
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    }
  },
  methods: {
    ...mapActions({
      $updateEducation: 'apimodel/makeRequest'
    }),
    updateSelected({ value, id = '' }, key, index) {
      if (key == 'degreeId') {
        this.formData[index].degree.id = value;
        return;
      }
      this.formData[index][key] = value;
    },
    selectDegree(e) {
      console.log(e);
      this.newEducation.degreeId = e.value;
    },
    disabled_dates(index) {
      const date = this.formData[index].startDate
        ? [
            new Date(this.formData[index].startDate).getFullYear(),
            new Date(this.formData[index].startDate).getMonth(),
            new Date(this.formData[index].startDate).getDate() + 1
          ]
        : [new Date()];

      //   return {
      //     to: new Date(...date)
      //   };

      //   const date = this.formData[index].startDate
      //     ? [
      //         new Date(this.formData[index].startDate).getFullYear(),
      //         new Date(this.formData[index].startDate).getMonth(),
      //         new Date(this.formData[index].startDate).getDate() + 1
      //       ]
      //     : [new Date()];

      return {
        ranges: [
          {
            from: new Date(0, 0, 0),
            to: new Date(...date)
          },
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    },

    disabled_dates_for_new_educationOld() {
      const sd = this.newEducation.startDate;

      const date = sd
        ? [
            new Date(sd.startDate).getFullYear(),
            new Date(sd.startDate).getMonth(),
            new Date(sd.startDate).getDate() + 1
          ]
        : [new Date()];

      return {
        ranges: [
          {
            from: new Date(0, 0, 0),
            to: new Date(...date)
          },
          {
            from: new Date(),
            to: new Date(3000, 11, 30)
          }
        ]
      };
    },
    updateEducation(id) {
      const education = this.formData.find((data) => data.id === id);
      const payload = {
        id,
        degreeId: education.degree.id,
        institute: education.institute,
        startDate: education.startDate,
        endDate: education.endDate,
        details: education.details,
        stillGoing: education.stillGoing
      };
      this.loading = true;

      seekerService
        .updateEducation(payload)
        .then((res) => {
          this.$handleSuccess(res);
          this.$emit('on-success', { tab: 'Education' });
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addNewEducation() {
      this.addingEducation = true;
      seekerService
        .addNewEducation(this.newEducation)
        .then((res) => {
          this.$handleSuccess(res);
          this.$emit('on-success', { tab: 'Education' });
          this.addModal = false;
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.addingEducation = false;
        });
    },
    confirmDelete(education) {
      this.selectedEducation = education;
      this.deleteModal = true;
    },
    deleteNow() {
      this.deleteModal = false;
      this.deleting = true;
      seekerService
        .deleteEducation(this.selectedEducation.id)
        .then((res) => {
          this.$handleSuccess(res);
          this.$emit('on-success', { tab: 'Education' });
        })
        .catch((e) => {
          this.$handleError(e);
        })
        .finally(() => {
          this.deleting = false;
        });
    }
  },
  mounted() {
    authService
      .getDegrees()
      .then((res) => {
        this.degrees = res.data.degrees.map((degree) => ({
          name: degree.title,
          value: degree.id
        }));
      })
      .catch()
      .finally();
  }
};
</script>

<style scoped lang="scss">
.mb-1x {
  margin-bottom: 8px;
  display: inline-block;
}
</style>
